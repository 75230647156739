import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  titleDashboard = 
  [{ title: "Nuova ricerca", subTitle: "Spazi disponibili", checked: true},
  { title: "Ricerche in corso", subTitle: "Cerca gli spazi", checked: false },
  // { title: "Calendario", subTitle: "Subtitle", checked: false },
  { title: "Spazi opzionati", subTitle: "Opzioni attive", checked: false, percent: 80},
  { title: "I tuoi tour", subTitle: "Tour effettuati", checked: false, percent: 45},
  { title:"I tuoi preventivi", subTitle: "Preventivi approvati", checked: false},
  { title:"I tuoi servizi", subTitle: "Servizi emessi", checked: false}]

  tabIndex = 0;

  val = 0;
  progress = 0;

  constructor() { }
  progressBar = document.querySelector('.progress-bar');
  intervalId;

  ngOnInit() {
    const getDownloadProgress = () => {
      console.log('getDownload', this);
      if (this.progress <= 45) {
        console.log('inside if', this.progress);
        this.progress = this.progress + 1;
      }
      else {
        clearInterval(this.intervalId);
      }
    }
    this.intervalId = setInterval(getDownloadProgress, 30);
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  onTabClick(index) {
    this.tabIndex = index;

    if(this.tabIndex == 0){

      this.val = 0;

    }

    if(this.tabIndex == 1){

      this.val = 1;

    }


    if(this.tabIndex == 2){

      this.val = 2;

    }


    if(this.tabIndex == 3){

      this.val = 3;

    }

    if(this.tabIndex == 4){

      this.val = 4;

    }

    if(this.tabIndex == 5){

      this.val = 5;

    }

    if(this.tabIndex == 6){

      this.val = 6;

    }


    if(this.tabIndex == 5){

      this.val = 5;

    }


  }

}
