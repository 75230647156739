import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material';

export interface Ricerca {

  city: string;
  context: string;
  space: string;
  description: string;
  from: Date;
  to: Date;
  numDays: number;
  price: number;
  status: boolean;

}

@Component({
  selector: 'app-spazi-opzionati',
  templateUrl: './spazi-opzionati.component.html',
  styleUrls: ['./spazi-opzionati.component.css']
})
export class SpaziOpzionatiComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  listElement: Ricerca[] = [

    { city: "Torino", context: 'Metropoli 3', space: "LX1110022", description: "Descrizione spazio", from: new Date("2020-02-12"), to: new Date("2020-02-12"), numDays: 14, price: 500, status: true },
    { city: "Bergamo", context: 'Metropoli 3', space: "LX1110022", description: "Descrizione spazio", from: new Date("2020-02-12"), to: new Date("2020-02-12"), numDays: 14, price: 300, status: true },
    { city: "Torino", context: 'Metropoli 3', space: "LX1110022", description: "Descrizione spazio", from: new Date("2020-02-12"), to: new Date("2020-02-12"), numDays: 7, price: 250, status: false },
    { city: "Rho", context: 'Metropoli 3', space: "LX1110022", description: "Descrizione spazio", from: new Date("2020-02-12"), to: new Date("2020-02-12"), numDays: 7, price: 200, status: false },
    { city: "Torino", context: 'Metropoli 3', space: "LX1110022", description: "Descrizione spazio", from: new Date("2020-02-12"), to: new Date("2020-02-12"), numDays: 14, price: 500, status: true },
    { city: "Bergamo", context: 'Metropoli 3', space: "LX1110022", description: "Descrizione spazio", from: new Date("2020-02-12"), to: new Date("2020-02-12"), numDays: 21, price: 500, status: true },
    { city: "Torino", context: 'Metropoli 3', space: "LX1110022", description: "Descrizione spazio", from: new Date("2020-02-12"), to: new Date("2020-02-12"), numDays: 14, price: 350, status: false },
    { city: "Rho", context: 'Metropoli 3', space: "LX1110022", description: "Descrizione spazio", from: new Date("2020-02-12"), to: new Date("2020-02-12"), numDays: 14, price: 400, status: false },

 
  ];

  displayedColumns: string[] = ['city', 'context', 'space', 'description', 'from', 'to', 'numDays', 'price', 'status'];
  dataSource = new MatTableDataSource<Ricerca>(this.listElement);


  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor() { }

  ngOnInit() {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;


  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
