import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';



export interface Ricerca {

  city: string;
  context: string;
  space: string;
  description: string;
  from: Date;
  to: Date;
  numDays: number;
  price: number;
  status: boolean;

}


@Component({
  selector: 'app-tuoi-preventivi',
  templateUrl: './tuoi-preventivi.component.html',
  styleUrls: ['./tuoi-preventivi.component.css']
})
export class TuoiPreventiviComponent implements OnInit {


  listElement: Ricerca[] = [

    { city: "Torino", context: 'Metropoli 3', space: "LX1110022", description: "Descrizione spazio", from: new Date("2020-02-12"), to: new Date("2020-02-12"), numDays: 14, price: 500, status: true },
    { city: "Bergamo", context: 'Metropoli 3', space: "LX1110022", description: "Descrizione spazio", from: new Date("2020-02-12"), to: new Date("2020-02-12"), numDays: 14, price: 500, status: true },
    { city: "Torino", context: 'Metropoli 3', space: "LX1110022", description: "Descrizione spazio", from: new Date("2020-02-12"), to: new Date("2020-02-12"), numDays: 14, price: 500, status: true },
    { city: "Rho", context: 'Metropoli 3', space: "LX1110022", description: "Descrizione spazio", from: new Date("2020-02-12"), to: new Date("2020-02-12"), numDays: 14, price: 500, status: true },

  ];

  displayedColumns: string[] = ['city', 'context', 'space', 'description', 'from', 'to', 'numDays', 'price', 'status'];
  dataSource = new MatTableDataSource<Ricerca>(this.listElement);

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor() { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;


  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}