import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';

export interface Ricerca {

  city: string;
  context: string;
  space: string;
  description: string;
  from: Date;
  to: Date;
  numDays: number;
  price: number;
  status: boolean;

}

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.css']
})
export class CalendarioComponent implements OnInit {

  // listElement: Ricerca[] = [

  //   { city: "Torino", context: 'Metropoli 3', space: "LX1110022", description: "Descrizione spazio", from: new Date("2020-02-12"), to: new Date("2020-02-12"), numDays: 14, price: 500, status: true },
  //   { city: "Bergamo", context: 'Metropoli 3', space: "LX1110022", description: "Descrizione spazio", from: new Date("2020-02-12"), to: new Date("2020-02-12"), numDays: 14, price: 500, status: true },
  //   { city: "Torino", context: 'Metropoli 3', space: "LX1110022", description: "Descrizione spazio", from: new Date("2020-02-12"), to: new Date("2020-02-12"), numDays: 14, price: 500, status: false },
  //   { city: "Rho", context: 'Metropoli 3', space: "LX1110022", description: "Descrizione spazio", from: new Date("2020-02-12"), to: new Date("2020-02-12"), numDays: 14, price: 500, status: false },

 
  // ];

  // displayedColumns: string[] = ['space', 'context', 'space', 'description', 'from', 'to', 'numDays', 'price', 'status'];
  // dataSource = new MatTableDataSource<Ricerca>(this.listElement);

  arrayDays : Array<Date> = [];

  arraySpaces: Array<any> = [{space:"ANC Spazio promo 4X4"}, {space:"ANC Spazio promo 3X2"},{space:"ANC Spazio promo 4X3"},{space:"ANC Spazio promo 3X4"},{space:"ANC Spazio promo 2X4"},{space:"ANC Spazio promo 4X2"},{space:"ANC Spazio promo 3X3"}];

  
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor() { }

  ngOnInit() {

    this.createDaysMonth();


  }

  createDaysMonth(){

    let d = new Date();

    let startDay = new Date(d.getFullYear(), d.getMonth(), 1);
    let endDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);

    console.log("Stampa primo giorno: " + startDay);
    console.log("Stampa ultimo giorno: " + endDay);


    // while(startDay < endDay){

    //   this.arrayDays.push(startDay);

    //   startDay.getDate() + 1;

    // }

  

  }

}
