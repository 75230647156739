import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { PriceFormatPipe } from './price-format.pipe';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule, MatSidenavModule, MatListModule, MatButtonModule, MatIconModule, MatCardModule, MatPaginatorModule, MatLabel, MatFormFieldModule, MatAutocompleteModule, MatBadgeModule, MatBottomSheetModule, MatCheckboxModule, MatChipsModule, MatButtonToggleModule, MatGridListModule, MatExpansionModule, MatDividerModule, MatDatepickerModule, MatInputModule, MatMenuModule, MatNativeDateModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSliderModule, MatSnackBarModule, MatTooltipModule, MatTabsModule, MatSortModule, MatTreeModule } from "@angular/material";
import { FlexLayoutModule } from "@angular/flex-layout";
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { RicercheInCorsoComponent } from './ricerche-in-corso/ricerche-in-corso.component';
import { MatTableModule } from '@angular/material/table';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NuovaRicercaComponent } from './nuova-ricerca/nuova-ricerca.component';
import { SpaziOpzionatiComponent } from './spazi-opzionati/spazi-opzionati.component';
import { TuoiTourComponent } from './tuoi-tour/tuoi-tour.component';
import { TuoiPreventiviComponent } from './tuoi-preventivi/tuoi-preventivi.component';
import { TuoiServiziComponent } from './tuoi-servizi/tuoi-servizi.component';
import { CalendarioComponent } from './calendario/calendario.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { A11yModule } from '@angular/cdk/a11y';
import { NgCircleProgressModule } from 'ng-circle-progress';

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    FooterComponent,
    PriceFormatPipe,
    ScrollTopComponent,
    RicercheInCorsoComponent,
    DashboardComponent,
    NuovaRicercaComponent,
    SpaziOpzionatiComponent,
    TuoiTourComponent,
    TuoiPreventiviComponent,
    TuoiServiziComponent,
    CalendarioComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatIconModule,
    FormsModule,
    MatDialogModule,
    FormsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    FlexLayoutModule,
    DateInputsModule,
    HttpClientModule,
    MatTableModule,
    MatCardModule,
    MatPaginatorModule,
    MatFormFieldModule,
    A11yModule,
    CdkStepperModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,
    MatTabsModule,
    NgCircleProgressModule.forRoot({})

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
