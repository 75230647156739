import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RicercheInCorsoComponent } from './ricerche-in-corso/ricerche-in-corso.component';


const routes: Routes = [

  {path:"", redirectTo:"/home", pathMatch:"full"},
  // {path:"ricercaincorso", component: RicercheInCorsoComponent},
  // {path:"", redirectTo:"/ricercaincorso", pathMatch:"full"},
  {path:"home", component: DashboardComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
